import { Dialog, DialogContent, DialogContentText } from "@mui/material";

// dialog to display while the first requests are sent
export default function WaitingDialog(props: WaitingDialogProps){
  return(
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          Please wait, while your request is being processed
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

type WaitingDialogProps = {};
