import { MouseEventHandler, createContext } from "react";
import WarshipUser from "../Types/WarshipUser.type"

const Context = createContext<ContextType>({});

export type ContextType = {
  user?: WarshipUser,
  topTabButtons?: {text: string, event: MouseEventHandler}[]
}

export default Context;
