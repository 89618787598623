import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, Divider, Paper, Stack, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import Mine from "./Components/Mine";
import styles from "./MineSweeper.style";
import { MineSweeperClass } from "./MineSweeperClass";
import { useCallback, useState } from "react";
import MineSweeperStore from "../../Models/MineSweeper/MineSweeperStore";

const MineSweeper = observer(() => {
  const [localMineSweeperInstance] = useState(() => new MineSweeperClass());
  const closingFunction = useCallback(() => {
    MineSweeperStore.toggleOpen();
    localMineSweeperInstance.createPlaceholderMatrix();
  }, [localMineSweeperInstance])

  return(
    <Dialog
      open={MineSweeperStore.isOpen}
      onClose={closingFunction}
      maxWidth={false}
    >
      <DialogContent>
        <Paper elevation={4} style={styles.container}>
          <Typography>
            Minesweeper
          </Typography>
          <Accordion style={styles.accordion}>
            <AccordionSummary style={{justifyContent: 'center'}}>
              <Typography textAlign={'center'}>New game editor</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                style={styles.outerStack}
                divider={<Divider orientation="horizontal" flexItem/>}
              >
                <Stack style={styles.innerStack}>
                  <Typography>Length of the field:</Typography>
                  <TextField
                    type="number"
                    onChange={e => localMineSweeperInstance.onNewGameValueChange(parseInt(e.target.value), "newGameLength")}
                    value={localMineSweeperInstance.newGameLength}
                  />
                </Stack>
                <Stack style={styles.innerStack}>
                  <Typography>Height of the field:</Typography>
                  <TextField
                    type="number"
                    onChange={e => localMineSweeperInstance.onNewGameValueChange(parseInt(e.target.value), "newGameHeight")}
                    value={localMineSweeperInstance.newGameHeight}
                  />
                </Stack>
                <Stack style={styles.innerStack}>
                  <Typography>Number of bombs:</Typography>
                  <TextField
                    type="number"
                    onChange={e => localMineSweeperInstance.onNewGameValueChange(parseInt(e.target.value), "newGameNumOfBombs")}
                    value={localMineSweeperInstance.newGameNumOfBombs}
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
            <AccordionActions>
              <Button onClick={localMineSweeperInstance.onNewGame}>
                Reset game
              </Button>
            </AccordionActions>
          </Accordion>
          {localMineSweeperInstance.state === -1 && <Typography variant="h4" textAlign={'center'} margin={'1vmax'}>You have lost</Typography>}
          {localMineSweeperInstance.state === 2 && <Typography variant="h4" textAlign={'center'} margin={'1vmax'}>You have won</Typography>}
          <Typography textAlign={'center'}>Remaining bombs: {localMineSweeperInstance.remainingNumberOfBombs}</Typography>
          <Box style={styles.box} onContextMenu={(e) => e.preventDefault()}>
            {localMineSweeperInstance.matrix.map((row, i) => {
              return(
                <Stack direction={'row'} key={i} width={'fit-content'}>
                  {row.map((elem, j) => {
                    return(
                      <Mine elem={elem} key={j} x={i} y={j} MineSweeperInstance={localMineSweeperInstance}/>
                    )
                  })}
                </Stack>
              )
            })}
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closingFunction}
          color="error"
        >
          Close game
        </Button>
      </DialogActions>
    </Dialog>
  )
});

export default MineSweeper;
