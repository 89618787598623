import { action, makeObservable, observable } from "mobx";

class MineSweeperStoreClass {
  isOpen: boolean = false;

  toggleOpen = () => {
    this.isOpen = !this.isOpen;
  }

  constructor(){
    makeObservable(this, {
      isOpen: observable,
      toggleOpen: action,
    })
  }
}

const inst = new MineSweeperStoreClass();

export default inst;
