import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { darkTheme, isDark as isDarkGlobal, lightTheme } from "./Globals/Global";
import { Key, useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CustomRouteObject, ViewProps } from "./Controllers/CustomRouter.type";
import customRouter from "./Controllers/CustomRouter";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import authTokenValidatorService from "./Services/AuthenticationServices/AuthTokenValidatorService";
import TopTab from "./Components/TopTab";
import Context, { ContextType } from "./Globals/Context";
import MineSweeper from "./Components/MineSweeper/MineSweeper";

function App() {
  // context state
  const [contextState, setContextState] = useState<ContextType>({user: authTokenValidatorService()});
  // theme state
  const [isDark, ] = useState(isDarkGlobal);
  // routes
  const routeOptions = useMemo<CustomRouteObject<ViewProps>[]>(() => customRouter.getRouter(), []);
  const navigate = useNavigate();

  // login when the token is not valid
  useEffect(() => {
    if(!contextState.user) navigate(customRouter.routes.login);
  }, [contextState.user, navigate]);

  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <CssBaseline/>
      <Context.Provider value={contextState}>
        <TopTab setContextState={setContextState}/>
        <Box id="app-container" height={!contextState.user ? '100% !important' : 'calc(100% - 4rem)'}>
          <Routes>
            {routeOptions.map((routeOption: CustomRouteObject<ViewProps>, i: Key) => {
              const params: ViewProps = {
                setContextState: setContextState
              };

              return (
                <Route
                  element={<routeOption.element {...params}/>}
                  path={routeOption.path}
                  key={i}
                />
              );
            })}
          </Routes>
        </Box>
        <MineSweeper/>
      </Context.Provider>
      <ToastContainer/>
    </ThemeProvider>
  );
}

export default App;
