const styles = {
  container: {
    padding: 20,
  },
  divider: {
    margin: 10,
  },
  box: {
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    alignItems: 'center',
  },
  accordion: {
    margin: '12px 0px',
  },
  outerStack: {
    gap: 8,
    alignItems: 'center',
  },
  innerStack: {
    maxWidth: 400,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
  },
} as const;

export default styles;
