import { jwtDecode } from "jwt-decode";
import { tokenName } from "../../Globals/Global";
import WarshipUser from "../../Types/WarshipUser.type";

// token validator function
const authTokenValidatorService = (): undefined | WarshipUser  => {
  const token = localStorage.getItem(tokenName);
  if (token === null) return undefined;

  try{
    const decodedToken = jwtDecode<WarshipUser & { exp: number, iat: number}>(token);
    return new Date(decodedToken.exp * 1000) > new Date()
      ? decodedToken
      : undefined
  }
  catch{ return undefined; }
};

export default authTokenValidatorService;
