import { Autocomplete, AutocompleteRenderInputParams, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Stack, Switch, TextField, Tooltip, Typography, Zoom } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomStyle, ResponseModel } from "../Globals/Global.type";
import GameService from "../Services/GameServices/GameService";
import CreateGameResponse from "../Models/Game/CreateGameResponse.type";
import WarshipUser from "../Types/WarshipUser.type";
import { useDebounce } from "usehooks-ts";
import UserService from "../Services/UserServices/UserService";
import FieldCreator, { FieldCreatorRef } from "./Draggables/FieldCreator";
import Ship from "../Types/Ship.type";

// Dialog to create games at any point
export default function NewGameDialog(props: NewGameDialogProps){
  const creatingFieldRef = useRef<FieldCreatorRef>(null);
  // states
  const [isPlayingAgainstBot, setPlayingAgainstBot] = useState(true);
  const [isUsingHitRule, setUsingHitRule] = useState(false);
  const [difficulty, setDifficulty] = useState(0);
  const [isCreatingField, setCreatingField] = useState(false);

  // user selection states
  const [username, setUsername] = useState('');
  const [users, setUsers] = useState<WarshipUser[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const debouncedUsername = useDebounce(username, 300);
  const userAutocompleteOptions = useMemo(() => users.map(u => ({label: u.username, id: u.id})), [users]);

  const onResultCallback = useMemo(() => props.onResultCallback, [props.onResultCallback]);

  // state handlers
  const onHitRulePaperClick = useCallback(() => setUsingHitRule(val => !val), []);
  const onPlayerPaperClick = useCallback(() => setPlayingAgainstBot(val => !val), []);
  const onNewGameDifficultyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => { setDifficulty(parseInt((e.target as HTMLInputElement).value))}, []);
  const onFieldPaperClick = useCallback(() => setCreatingField(val => !val), []);
  const onUserAutoCompleteChange = useCallback((e: React.SyntheticEvent<Element, Event>, value: { label: string, id: number } | null) => { setSelectedUserId(value === null ? value : value.id) }, []);
  const onUserAutoCompleteInputChange = useCallback((e: React.SyntheticEvent<Element, Event>, value: string) => { setUsername(value); }, []);

  // get the users for the autocomplete
  const getUsers = useCallback(async () => {
    const response = await UserService.getUsers({
      username: debouncedUsername
    });

    if(response.status === 200){
      setUsers(response.data.users);
    }
  }, [debouncedUsername]);

  // events
  const onStartNewGame = useCallback(async () => {
    let isShipsDataValid: boolean = false;
    let validatedShipsData: Omit<Ship, "isDestroyed" | "WarshipGameId" | "WarshipUserId">[] = [];

    // if creating a field, validate the ships on the frontend as well
    if(isCreatingField){
      [isShipsDataValid, validatedShipsData] = GameService.validateShipsData(creatingFieldRef.current?.shipsData);
      if(!isShipsDataValid) return;
    }

    const response = await GameService.createGame({
      opponentId: selectedUserId,
      isUsingHitRule: isUsingHitRule,
      difficulty: difficulty,
      ships: validatedShipsData.length > 0 ? validatedShipsData : undefined,
    });

    onResultCallback(response);
  }, [isCreatingField, selectedUserId, isUsingHitRule, difficulty, onResultCallback]);

  // remove the userid when switching to play against bot
  useEffect(() => {
    if(isPlayingAgainstBot) setSelectedUserId(null);
  }, [isPlayingAgainstBot]);

  // when the username changes, get the users
  useEffect(() => {
    if(debouncedUsername.length) getUsers();
  }, [debouncedUsername, getUsers]);

  return(
    <Dialog
      open={props.open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Create a new game</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems={'center'}>
          {/* Hit rule switch */}
          <Grid item xs={12} sm={6} height={'100%'}>
            <Paper style={styles.dialogPaper} onClick={onHitRulePaperClick}>
              <Stack style={styles.dialogStack}>
                <Typography textAlign={'center'}>
                  Would you like to use the hit rule?
                </Typography>
                <Tooltip
                  disableFocusListener
                  TransitionComponent={Zoom}
                  title={
                    <>
                      <Typography variant="subtitle2">Any player after a succesful hit, could try to hit again</Typography>
                      <Typography variant="subtitle2">Otherwise the players try to hit in a turn based manner</Typography>
                    </>
                  }
                >
                  <IconButton>
                    <InfoOutlinedIcon/>
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack style={styles.rowStack}>
                <Typography>Normal mode</Typography>
                <Switch checked={isUsingHitRule} readOnly/>
                <Typography>Hit rule mode</Typography>
              </Stack>
            </Paper>
          </Grid>

          {/* User switch */}
          <Grid item xs={12} sm={6}>
            <Paper style={styles.dialogPaper} onClick={onPlayerPaperClick}>
              <Typography textAlign={'center'}>
                Play against bot or user
              </Typography>
              <Stack style={styles.rowStack}>
                <Typography>User</Typography>
                <Switch checked={isPlayingAgainstBot} readOnly/>
                <Typography>Bot</Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} height={'100%'}>
            <Paper style={styles.dialogPaper}>
              {isPlayingAgainstBot ? (
                // Difficulty radio menu
                <>
                  <Stack style={styles.dialogStack}>
                    <Typography textAlign={'center'}>
                      Choose the difficulty of your next game
                    </Typography>
                    <Tooltip
                      disableFocusListener
                      TransitionComponent={Zoom}
                      title={
                        <>
                          <Typography variant="subtitle2">Hard: optimised bot</Typography>
                          <Typography variant="subtitle2">Normal: misses occasionally</Typography>
                          <Typography variant="subtitle2">Easy: misses a lot</Typography>
                        </>
                      }
                    >
                      <IconButton>
                        <InfoOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <FormControl>
                    <RadioGroup value={difficulty} onChange={e => onNewGameDifficultyChange(e)}>
                      <FormControlLabel value={0} control={<Radio/>} label="Hard" />
                      <FormControlLabel value={1} control={<Radio/>} label="Normal" />
                      <FormControlLabel value={2} control={<Radio/>} label="Easy" />
                    </RadioGroup>
                  </FormControl>
                </>
              ) : (
                // Selecting a user opponent
                <>
                  <Typography textAlign={'center'}>
                    Choose your opponent by username
                  </Typography>
                  <Autocomplete
                    disablePortal
                    options={userAutocompleteOptions}
                    style={styles.autocomplete}
                    renderInput={(params: AutocompleteRenderInputParams) => (<TextField {...params}/>)}
                    noOptionsText="Start typing for usernames"
                    onChange={onUserAutoCompleteChange}
                    onInputChange={onUserAutoCompleteInputChange}
                  />
                </>
              )}
            </Paper>
          </Grid>

          {/* Field editor switch */}
          <Grid item xs={12} sm={6}>
            <Paper style={styles.dialogPaper} onClick={onFieldPaperClick}>
              <Typography textAlign={'center'}>
                Do you want to create your own field?
              </Typography>
              <Stack style={styles.rowStack}>
                <Typography>No</Typography>
                <Switch checked={isCreatingField} readOnly/>
                <Typography>Yes</Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>

        {/* Field creator component */}
        {isCreatingField && <FieldCreator ref={creatingFieldRef}/>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onStartNewGame}>Create game</Button>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const styles: CustomStyle = {
  dialogPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1rem',
    gap: '0.5rem',
  },
  dialogStack: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    padding: '0.5rem',
  },
  rowStack: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem',
  },
  autocomplete: {
    width: '100%',
  },
};

type NewGameDialogProps = {
  open: boolean,
  onClose: () => void,
  onResultCallback: (result: ResponseModel<CreateGameResponse>) => void,
};
