import { action, computed, makeObservable, observable } from "mobx"
import { createMineSweeperMatrix } from "./utils";
import { toast } from "react-toastify";
import MinePng from '../../Images/mine.png';
import React from "react";
import styles from "./Components/Mine.style";
import FlagIcon from "@mui/icons-material/Flag";

export class MineSweeperClass {
  height: number;
  width: number;
  numOfBombs: number;
  startingX: number;
  startingY: number;

  newGameHeight: number;
  newGameLength: number;
  newGameNumOfBombs: number;

  // 0: new, -1: lost, 1: ongoing, 2: won
  state: number;

  // -1: bomb, anything else: bombs nearby
  matrix: number[][];

  // -1: unselectable, 0: invisible, 1: visible
  visibilityMatrix: number[][];

  constructor(val?: MineSweeperClassConstructorType){
    makeObservable(this, {
      height: observable,
      width: observable,
      numOfBombs: observable,
      startingX: observable,
      startingY: observable,

      newGameHeight: observable,
      newGameLength: observable,
      newGameNumOfBombs: observable,

      state: observable,
      matrix: observable,
      visibilityMatrix: observable,
      display: computed,

      remainingNumberOfBombs: computed,

      createPlaceholderMatrix: action,
      createNewMatrix: action,
      onMark: action,
      onHit: action,
      onCheckWin: action,
      onPaperClick: action,
      onPaperContextClick: action,
      onPreloadImages: action,
    });

    // defaults, to be set in onNewGame
    this.height = 0;
    this.width = 0;
    this.numOfBombs = 0;
    this.startingX = 0;
    this.startingY = 0;
    this.state = 0;
    this.matrix = [];
    this.visibilityMatrix = [];

    this.newGameHeight = 10;
    this.newGameLength = 10;
    this.newGameNumOfBombs = 10;

    this.onPreloadImages();
    this.onNewGame();
  }

  get remainingNumberOfBombs(): number {
    if(this.state === 0) return 0;
    
    return this.numOfBombs - this.visibilityMatrix.reduce(
      (acc, cur) => acc + cur.reduce(
        (acc2, cur2) => cur2 === -1 ? acc2 + 1 : acc2
      , 0)
    , 0);
  }

  onNewGameValueChange = (
    val: number,
    key: "newGameHeight" | "newGameLength" | "newGameNumOfBombs"
  ) => {
    this[key] = val;
  }

  onNewGame = () => {
    if(isNaN(this.newGameLength)){ toast.error("Length is not a number"); return; }
    if(isNaN(this.newGameHeight)){ toast.error("Height is not a number"); return; }
    if(isNaN(this.newGameNumOfBombs)){ toast.error("Number of bombs is not a number"); return; }

    if(this.newGameLength % 1 !== 0){ toast.error("Length is not a whole number"); return; }
    if(this.newGameHeight % 1 !== 0){ toast.error("Height is not a whole number"); return; }
    if(this.newGameNumOfBombs % 1 !== 0){ toast.error("Number of bombs is not a whole number"); return; }

    if(this.newGameLength < 5){ toast.error("Minimum game length is 5"); return; }
    if(this.newGameHeight < 5){ toast.error("Minimum game height is 5"); return; }
    if(this.newGameNumOfBombs < 5){ toast.error("Minimum number of bombs is 5"); return; }
    if(this.newGameLength * this.newGameHeight - this.newGameNumOfBombs < 10){ toast.error("There should be at least 10 free spaces"); return; }
    if(this.newGameNumOfBombs > this.newGameHeight * this.newGameLength * 0.9){ toast.error("Less than 90% of the field can be bombs"); return; }

    this.width = this.newGameLength;
    this.height = this.newGameHeight;
    this.numOfBombs = this.newGameNumOfBombs;

    this.createPlaceholderMatrix();
  }

  createPlaceholderMatrix = () => {
    this.state = 0;

    this.matrix = Array(this.height).fill(Array(this.width).fill(0));
    this.visibilityMatrix = Array(this.height).fill(Array(this.width).fill(0));
  }

  createNewMatrix = (startingX: number = this.startingX, startingY: number = this.startingY) => {
    this.state = 0;

    this.matrix = createMineSweeperMatrix({
      height: this.height,
      width: this.width,
      numOfBombs: this.numOfBombs,
      startingX: startingX,
      startingY: startingY,
    });

    this.visibilityMatrix = Array(this.height).fill(Array(this.width).fill(0));
    this.visibilityMatrix.forEach((_, i) => {
      this.visibilityMatrix[i] = [...this.visibilityMatrix[i]];
    });
  }

  onMark = (x: number, y: number) => {
    if(this.state !== 1) return;
    if(this.visibilityMatrix[x][y] === 1) return;

    if(this.visibilityMatrix[x][y] === -1){
      this.visibilityMatrix[x][y] = 0;
      this.onCheckWin();
      return;
    }
    if(this.visibilityMatrix[x][y] === 0){
      this.visibilityMatrix[x][y] = -1;
      this.onCheckWin();
      return;
    }
  };

  onHit = (x: number, y: number) => {
    if(this.visibilityMatrix[x][y] === -1) return;
    if(this.state === -1 || this.state === 2) return;
    if(this.state === 0) this.createNewMatrix(x, y);

    this.state = 1;
    this.visibilityMatrix[x][y] = 1;

    if(this.matrix[x][y] === -1){
      this.state = -1;
      return;
    }

    if(this.matrix[x][y] === 0){
      for(let i = -1; i < 2; i++){
        for(let j = -1; j < 2; j++){
          const newX = x + i;
          const newY = y + j;
          if(
            newX < 0
            || newY < 0
            || newX >= this.height 
            || newY >= this.width
            || this.visibilityMatrix[newX][newY]
          ) continue;

          this.onHit(newX, newY);
        }
      }
    }

    this.onCheckWin();
  }

  onCheckWin = () => {
    for(let y = 0; y < this.width; y++){
      for(let x = 0; x < this.height; x++){
        if(
          false
          || (this.visibilityMatrix[x][y] === 0)                              // everything is clicked
          || (this.matrix[x][y] === -1 && this.visibilityMatrix[x][y] !== -1) // all mines are flagged
          || (this.visibilityMatrix[x][y] === -1 && this.matrix[x][y] !== -1) // only the mines are flagged
        ) return;
      }
    }

    this.state = 2;
  };

  onPaperClick = (x: number, y: number) => {
    this.onHit(x, y);
  }

  onPaperContextClick = (e: React.MouseEvent<HTMLDivElement>, x: number, y: number) => {
    e.preventDefault();
    this.onMark(x, y);
  }

  onPreloadImages = () => {
    const img = new Image();
    img.src = MinePng;
  }

  get display(): (JSX.Element | number | null | undefined)[][] {
     return this.matrix.map((row, x) => row.map((elem, y) => {
      // display all the bombs, when the game is lost
      if(
        this.state === -1
        && elem === -1
      ){
        return <img
          alt='mine'
          src={MinePng}
          style={styles.image}
        />
      }
  
      // display flags
      if(this.visibilityMatrix[x][y] === -1) {
        return <FlagIcon style={styles.flag}/>;
      }
  
      // display visible numbers
      if(this.visibilityMatrix[x][y] === 1){
        return elem === 0 ? null : elem;
      }
  
      // display nothing
      return undefined;
    }))
  }
};

type MineSweeperClassConstructorType = {
  height?: number,
  width?: number,
  numOfBombs?: number,
  startingX?: number,
  startingY?: number,
};
