type Pagination = {
  limit: number,
  offset: number,
};

export const defaultPagination: Pagination = {
  limit: 5,
  offset: 0,
}

export default Pagination;