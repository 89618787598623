import { Box, Grid, IconButton, Paper, Stack, Switch, Typography } from "@mui/material";
import { WarshipGameWithGrid, warshipGameDifficultyToString } from "../Types/WarshipGame.type";
import GameGridImage from "./GameGridImage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import HitService from "../Services/HitServices/HitService";
import Context from "../Globals/Context";
import { CustomStyle } from "../Globals/Global.type";
import GameService from "../Services/GameServices/GameService";
import ReplayIcon from '@mui/icons-material/Replay';

// generic game grid component
export default function GameGrid(props: GameGridProps){
  const context = useContext(Context);
  const [isAutoRequesting, setAutoRequesting] = useState(false);
  const [game, setGame] = useState(props.game);
  const onReload = useMemo(() => props.onReload, [props.onReload]);
  const gameOverText = useMemo(() => {
    return GameService.gameToGameOverText(game, context.user);
  }, [game, context.user]);

  // event handler
  const onAutoRequestClick = useCallback(() => setAutoRequesting(val => !val), []);

  // registering a hit when an empty square is hit
  const onEmptyBoxClick = useCallback(async (x:number, y: number) => {
    const response = await HitService.createHit({
      hit: {
        x: x,
        y: y,
        WarshipGameId: game.id,
      }
    });
    
    if(response.status === 200){
      setGame(response.data.warshipGame);
    }
  }, [game.id]);

  // start the interval to automatically reqeuest
  useEffect(() => {
    if(!isAutoRequesting) return;

    const intervalId = setInterval(() => {
      onReload();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [isAutoRequesting, onReload]);

  // reload the game
  useEffect(() => {
    setGame(props.game);
  }, [props.game]);

  return(
    <Box style={styles.box}>
      {/* Display the end of the game */}
      {gameOverText && 
        <Typography style={styles.gameOverText}>
          {gameOverText}
        </Typography>
      }

      {/* Display the mode and the difficulty */}
      <Stack style={styles.rowStack} onClick={onReload}>
        <Typography style={styles.infoText}>
          {props.game.isUsingHitRule ? 'Hit rule mode' : 'Normal mode'}{warshipGameDifficultyToString(props.game)}
        </Typography>
        <IconButton>
          <ReplayIcon/>
        </IconButton>
      </Stack>

      {/* Auto request switch for PVP games */}
      {game.WarshipUserId2 !== null &&
        <Box onClick={onAutoRequestClick} style={styles.autoRequestBox}>
          <Typography>Reload automatically every 5 seconds</Typography>
          <Stack style={styles.rowStack}>
            <Typography>No</Typography>
            <Switch checked={isAutoRequesting} readOnly/>
            <Typography>Yes</Typography>
          </Stack>
        </Box>
      }

      {/* Game fields */}
      <Grid container overflow={'auto'}>
        <Grid item xs={12} md={6} style={styles.gridItem}>
          <Paper style={styles.paper}>
            <Typography style={styles.paperTypography}>
              {game.WarshipUserId2 === null
                ? 'The bot\'s ships'
                : game.WarshipUserId1 === context.user?.id
                  ? `${game.WarshipUser2?.username ?? 'Unknown user'}'s ships`
                  : `${game.WarshipUser1?.username ?? 'Unknown user'}'s ships`
              }
            </Typography>
            {game.grid.map((row, i) => {
              return(
                <Stack key={i} style={styles.paperStack}>
                  {row.map((elem, j) =>
                    <Box style={styles.gridItemBox} key={j}>
                      <GameGridImage
                        onEmptyBoxClick={!game.isFinished ? onEmptyBoxClick : undefined}
                        x={j}
                        y={i}
                        key={`${i}${j}`}
                        elem={elem}
                      /> 
                    </Box>
                  )}
                </Stack>
              )
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridItem}>
          <Paper style={styles.paper}>
            <Typography style={styles.paperTypography}>Your ships</Typography>
            {game.ownGrid.map((row, i) => {
              return(
                <Stack key={i} style={styles.paperStack}>
                  {row.map((elem, j) => 
                    <Box style={styles.gridItemBox} key={j}>
                      <GameGridImage
                        x={j}
                        y={i}
                        key={`${i}${j}`}
                        elem={elem}
                      />
                    </Box>
                   )}
                </Stack>
              )
            })}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
};

const styles: CustomStyle = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  gameOverText: {
    textAlign: 'center',
    padding: '1rem',
    fontSize: '2vmax',
  },
  infoText: {
    textAlign: 'center',
    fontSize: '1vmax',
  },
  autoRequestBox: {
    cursor: 'pointer',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
  gridItemBox: {
    width: '10%',
    paddingTop: '10%',
    position: 'relative',
    border: '1px solid white',
    backgroundColor: 'rgb(40, 40, 40)',
  },
  paper: {
    padding: '1.5rem',
    zIndex: 50,
    width: '95%',
    maxWidth: 700,
  },
  paperTypography: {
    fontSize: '2vmax',
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
  paperStack: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  rowStack: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  }
};

type GameGridProps = {
  game: WarshipGameWithGrid,
  onReload: () => Promise<void>,
}
