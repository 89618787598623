import { toast } from "react-toastify";

const createMineSweeperMatrix = (props: createMineSweeperMatrixProps): number[][] => {

  if(
    props.width < 0
    || props.height < 0
    || props.numOfBombs < 0
    || props.startingX < 0
    || props.startingY < 0
    || props.startingY >= props.width
    || props.startingX >= props.height
    || props.width * props.height - props.numOfBombs < 10
  ){
    toast.error("Not possible to create field");
    return [];
  }

  const matrix: number[][] = Array(props.height).fill(Array(props.width).fill(0));
  matrix.forEach((_, i) => {
    matrix[i] = [...matrix[i]];
  });

  for(let i = 0; i < props.numOfBombs; i++){
    let x, y;
    while(true){
      x = Math.floor(Math.random() * props.height);
      y = Math.floor(Math.random() * props.width);

      if(
        matrix[x][y] !== -1
        && (
          Math.abs(props.startingX - x) > 1
          || Math.abs(props.startingY - y) > 1
        )
      ){
        break;
      }
    }

    matrix[x][y] = -1;

    for(let j = -1; j < 2; j++){
      for(let k = -1; k < 2; k++){
        if(
          x + j < 0
          || x + j >= props.height
          || y + k < 0
          || y + k >= props.width
        ) continue;

        if(matrix[x + j][y + k] !== -1){
          matrix[x + j][y + k] += 1;
        }
      }
    }
  }

  return matrix;
};

type createMineSweeperMatrixProps = {
  height: number;
  width: number;
  numOfBombs: number;
  startingX: number;
  startingY: number;
};

export {
  createMineSweeperMatrix
};