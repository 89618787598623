import customRouter from "../../Controllers/CustomRouter";
import { APIUrl, tokenName } from "../../Globals/Global";
import { ResponseModel } from "../../Globals/Global.type";
import fetchData from "../fetchData";
import errorHandling from "../errorHandling";
import GetUsersResponse from "../../Models/User/GetUsersResponse.type";
import GetUsersData from "../../Models/User/GetUsersData.type";

class LocalService {
  async getUsers(data: GetUsersData): Promise<ResponseModel<GetUsersResponse>> {
    const response: ResponseModel<GetUsersResponse> = await fetchData(
      APIUrl + customRouter.apiRoutes.user.getUsers,
      'POST',
      { Authorization: localStorage.getItem(tokenName) },
      data
    );

    // error handling
    if(response.status !== 200){
      errorHandling(response);
    }

    return response;
  }
}

const UserService = new LocalService();

export default UserService;
