import { toast } from "react-toastify";
import { ResponseError } from "../Globals/Global.type";

const errorHandling = (response: ResponseError) => {
  if(Array.isArray(response.errorMessage)){
    response.errorMessage.forEach((e) => toast.error(e.message));
  }
  else if(response.errorMessage){
    toast.error(response.errorMessage);
  }
}

export default errorHandling;
