import { CustomStyle } from "../../Globals/Global.type";

const LoginStyles: CustomStyle = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem',
    overflow: 'auto',
    width: '100%',
    maxWidth: 'fit-content',
  },
  paperBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
  },
  input: {
    margin: '1rem 0',
  },
  loginButton: {
    margin: '2rem',
    marginBottom: '0.5rem',
  },
  registerButton: {
    margin: '2rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  registerBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
};

export default LoginStyles;
