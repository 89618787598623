const styles = {
  paper: {
    margin: 4,
    padding: 4,
    width: '3vmax',
    height: '3vmax',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2vmax',
    fontWeight: 'bold',
  },
  flag: {
    fontSize: '2vmax',
    color: 'limegreen',
  },
  image: {
    width: '100%',
    height: '100%',
    transform: 'scale(1.4)',
    filter: 'brightness(0) saturate(100%) invert(34%) sepia(94%) saturate(7260%) hue-rotate(355deg) brightness(104%) contrast(130%)',
  },
};

export const sx = {
  paper: {
    '&:hover': {
      backgroundColor: 'firebrick !important',
      cursor: 'pointer',
    },
  },
};

export default styles;
