import Game, { GameProps } from "../Views/Game/Game";
import Home, { HomeProps } from "../Views/Home/Home";
import Login, { LoginProps } from "../Views/Login/Login";
import { CustomRouteObject, ViewProps } from "./CustomRouter.type";

class CustomRouter {
  private customRouters: CustomRouteObject<ViewProps>[] = [
    {
      path: "/",
      element: Home,
    } as CustomRouteObject<HomeProps>,
    {
      path: "/warship/",
      element: Home,
    } as CustomRouteObject<HomeProps>,
    {
      path: "/warship/login",
      element: Login,
    } as CustomRouteObject<LoginProps>,
    {
      path: "/warship/game/:gameId",
      element: Game,
    } as CustomRouteObject<GameProps>,
  ];

  routes = {
    login: "/warship/login",
    home: "/warship/",
    game: "/warship/game/:gameId",
  } as const;

  apiRoutes = {
    authentication:{
      login: "login", 
      registration: "user", 
    },
    game: {
      createGame: "game",
      getGames: "game/games",
      getGame: "game/game",
    },
    hit: {
      createHit: "hit",
    },
    user: {
      getUsers: "user/users",
    },
  } as const;

  // returns the browser routes from customRouters
  public getRouter: () => CustomRouteObject<ViewProps>[] = () => {
    let routes: CustomRouteObject<ViewProps>[] = this.customRouters;
    return routes;
  };
}

const customRouter = new CustomRouter();
export default customRouter;
