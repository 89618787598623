import { Button, Paper, Stack } from "@mui/material";
import { CustomStyle } from "../Globals/Global.type";
import { useCallback, useContext } from "react";
import { tokenName } from "../Globals/Global";
import Context, { ContextType } from "../Globals/Context";
import MineSweeperStore from "../Models/MineSweeper/MineSweeperStore";

export default function TopTab(props: TopTabProps){
  const context = useContext(Context);
  const onLogoutButton = useCallback(() => {
    localStorage.removeItem(tokenName);
    props.setContextState(val => {
      return {
        ...val,
        user: undefined
      }
    });
  }, [props]);

  const onPlayMineSweeper = useCallback(() => {
    MineSweeperStore.toggleOpen();
  }, []);

  if(!context.user) return null;

  return(
    <Paper style={styles.paper}>
      <Stack style={styles.stack}>
        {context.topTabButtons?.map((button, i) => <Button key={i} onClick={button.event}>
          {button.text}
        </Button>)}
        <Button onClick={onPlayMineSweeper}>Minesweeper</Button>
        <Button onClick={onLogoutButton}>Logout</Button>
      </Stack>
    </Paper>
  );
}

const styles: CustomStyle = {
  paper: {
    width: '100%',
    height: '4rem',
  },
  stack: {
    flexDirection: 'row',
    overflowX: 'auto',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  }
}

type TopTabProps = {
  setContextState: React.Dispatch<React.SetStateAction<ContextType>>,
};
