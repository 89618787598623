import './Game.css';
import { useCallback, useEffect, useMemo, useState } from "react"
import GameGrid from "../../Components/GameGrid"
import { WarshipGameWithGrid } from "../../Types/WarshipGame.type"
import { ContextType } from "../../Globals/Context";
import { useNavigate, useParams } from "react-router-dom";
import GameService from "../../Services/GameServices/GameService";
import customRouter from "../../Controllers/CustomRouter";
import WaitingDialog from '../../Components/WaitingDialog';
import NewGameDialog from '../../Components/NewGameDialog';
import CreateGameResponse from '../../Models/Game/CreateGameResponse.type';
import { ResponseModel } from '../../Globals/Global.type';

export default function Game(props: GameProps){
  const navigate = useNavigate();
  const setContextState = useMemo(() => props.setContextState, [props.setContextState]);
  const { gameId } = useParams<GameParams>();
  const [isNewGameDialog, setNewGameDialog] = useState(false);
  const [game, setGame] = useState<WarshipGameWithGrid | undefined>();

  // event handlers
  const onOpenNewGameDialog = useCallback(() => setNewGameDialog(true), []);
  const onCloseNewGameDialog = useCallback(() => setNewGameDialog(false), []);

  // requesting the game defined in the route
  const getGame = useCallback(async () => {
    if(!gameId){
      navigate(customRouter.routes.home);
      return;
    }
    const id = parseInt(gameId);
    if(isNaN(id)) navigate(customRouter.routes.home);

    const response = await GameService.getGame({id: id});

    if(response.status === 200){
      setGame(response.data!.warshipGame);
    }
    else {
      navigate(customRouter.routes.home);
    }
  }, [gameId, navigate]);

  // navigate to the home route
  const onHomeButton = useCallback(() => {
    navigate(customRouter.routes.home);
  }, [navigate]);

  // create a new game and navigate to play it
  const onNewGame = useCallback(async (response: ResponseModel<CreateGameResponse>) => {
    if(response.status === 200){
      navigate(customRouter.routes.game.replaceAll(':gameId', response.data.warshipGame.id.toString()));
      onCloseNewGameDialog()
    }
  }, [ navigate, onCloseNewGameDialog ]);

  // setup the top tab buttons
  const setTopTabButtons = useCallback(() => {
    setContextState(val => ({
      ...val,
      topTabButtons: [
        {text: 'Home', event: onHomeButton},
        {text: 'Start new game', event: onOpenNewGameDialog},
      ],
    }));
  }, [setContextState, onHomeButton, onOpenNewGameDialog]);

  // setup the top tab button and request the game
  useEffect(() => { getGame(); }, [getGame]);
  useEffect(() => { setTopTabButtons(); }, [setTopTabButtons]);

  if(game === undefined) return <WaitingDialog/>

  return(
    <>
      <GameGrid
        game={game}
        onReload={getGame}
      />

      <NewGameDialog
        open={isNewGameDialog}
        onClose={onCloseNewGameDialog}
        onResultCallback={onNewGame}
      />
    </>
  )
}

type GameParams = {
  gameId: string,
};

export type GameProps = {
  setContextState: React.Dispatch<React.SetStateAction<ContextType>>,
};
