import Hit from "./Hit.type";
import Ship from "./Ship.type";
import WarshipUser from "./WarshipUser.type";

type WarshipGame = {
  id: number;
  x: number;
  y: number;
  difficulty: number;
  isUsingHitRule: boolean;
  isFinished: boolean;
  nextTurnWarshipUserId: number;
  WarshipUserId1: number;
  WarshipUserId2: number | null;

  WarshipUser1?: WarshipUser;
  WarshipUser2?: WarshipUser;
  Ships?: Ship[];
  Hits?: Hit[];
};

export const warshipGameToOpponentName = (game: WarshipGame, user: WarshipUser | undefined): string => {
  const unknownResult = 'Unknown user';
  if(user === undefined) return unknownResult;
  if(game.WarshipUserId2 === null) return 'Bot';

  if(game.WarshipUserId1 === user.id) return game.WarshipUser2?.username ?? unknownResult;
  else return game.WarshipUser1?.username ?? unknownResult;
}

export const warshipGameDifficultyToString = (game: WarshipGame): string => {
  if(game.WarshipUserId2 !== null) return '';
  switch(game.difficulty){
    case 0: return ' - Hard';
    case 1: return ' - Normal';
    case 2: return ' - Easy';
    default: return ' - Unknown'
  }
}

export type WarshipGameWithGrid = WarshipGame & {
  grid: WarshipGameGrid,
  ownGrid: WarshipGameGrid,
}

export type WarshipGameGrid = {
  shipSize?: number,
  shipElementIndex?: number,
  animationIndex?: number,
  animationCount?: number,
  isShip?: number,
  isHorizontal?: boolean,
  isHit?: boolean,
  isDestroyed?: boolean,
}[][];

export default WarshipGame;
