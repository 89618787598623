import { Paper } from "@mui/material";
import { MineSweeperClass } from "../MineSweeperClass";
import { observer } from "mobx-react-lite";
import styles, { sx } from "./Mine.style";

const Mine = observer((props: MineProps) => {
  return(
    <Paper
      onClick={() => props.MineSweeperInstance.onPaperClick(props.x, props.y)}
      onContextMenu={(e) => props.MineSweeperInstance.onPaperContextClick(e, props.x, props.y)}
      elevation={12}
      style={{
        ...styles.paper,
        backgroundColor: props.MineSweeperInstance.display[props.x][props.y] === null ? '#333333' : undefined,
      }}
      sx={sx.paper}
    >
      {props.MineSweeperInstance.display[props.x][props.y]}
    </Paper>
  );
});

type MineProps = {
  MineSweeperInstance: MineSweeperClass,
  elem: number,
  x: number,
  y: number,
}

export default Mine;
