import { Box, Grid, IconButton, Paper, Skeleton, Typography } from "@mui/material";
import "./Home.css";
import { useCallback, useEffect, useState } from "react";
import { WarshipGameWithGrid } from "../../Types/WarshipGame.type";
import GameService from "../../Services/GameServices/GameService";
import { ContextType } from "../../Globals/Context";
import { CustomStyle, ResponseModel } from "../../Globals/Global.type";
import GameGridHomeIcon from "../../Components/GameGridHomeIcon";
import Pagination, { defaultPagination } from "../../Types/Pagination.type";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import customRouter from "../../Controllers/CustomRouter";
import WaitingDialog from "../../Components/WaitingDialog";
import CreateGameResponse from "../../Models/Game/CreateGameResponse.type";
import NewGameDialog from "../../Components/NewGameDialog";

export default function Home(props: HomeProps){
  const navigate = useNavigate();
  const [isSkeleton, setSkeleton] = useState(false);
  const [isNewGameDialog, setNewGameDialog] = useState(false);
  // games to render and select from
  const [games, setGames] = useState<WarshipGameWithGrid[]>([]);
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);

  // event handlers
  const onOpenNewGameDialog = useCallback(() => setNewGameDialog(true), []);
  const onCloseNewGameDialog = useCallback(() => setNewGameDialog(false), []);

  // get the available games
  const getGames = useCallback(async () => {
    setSkeleton(true);
    const response = await GameService.getGames(pagination);

    if(response.status === 200 && response.data !== undefined){
      setGames(val => {
        let newGames = response.data.warshipGames.filter(game => !val.map(v => v.id).includes(game.id));
        return [...val, ...newGames];
      });
    }
    setSkeleton(false);
  }, [pagination]);

  // create a new game and append it to the start of the array
  const onNewGame = useCallback(async (response: ResponseModel<CreateGameResponse>) => {
    if(response.status === 200){
      onCloseNewGameDialog();
      setGames(val => [response.data!.warshipGame, ...val]);
    }
  }, [ onCloseNewGameDialog ]);

  // navigate to game view, when clicking a card
  const onGoToGame = useCallback((id: number) => {
    navigate(customRouter.routes.game.replace(':gameId', id.toString()));
  }, [navigate]);

  // setting the pagination and requesting more games
  const onRequestMore = useCallback(() => {
    if(pagination.offset > games.length){
      toast.info('You don\'t have any more games');
      return;
    }

    setPagination(val => ({
      ...val,
      offset: val.offset + 5,
    }));
  }, [pagination.offset, games.length]);

  // setup the top tab buttons
  const setTopTabButtons = useCallback(() => {
    props.setContextState!(val => ({
      ...val,
      topTabButtons: [
        {text: 'Start new game', event: onOpenNewGameDialog},
      ]
    }));
  }, [props.setContextState, onOpenNewGameDialog]);

  // get games on start
  useEffect(() => {
    setTopTabButtons();
    getGames();
  }, [getGames, setTopTabButtons]);

  // wait for the first request
  if(isSkeleton && games.length === 0) return <WaitingDialog/>

  return(
    <Box style={styles.box}>      
      <Grid container style={styles.grid}>
        {games.map((game, i) => <GameGridHomeIcon game={game} key={i} onGoToGame={onGoToGame}/>)}
        {/* Skeleton while requesting more */}
        {isSkeleton ? (
          <Grid
            item
            xs={12} sm={6} md={4} lg={3}
            style={styles.gridItem}
            className="homeGridHover"
          >
            <Paper style={styles.requestPaper}>
              <Skeleton variant="text" animation="wave" style={styles.skeletonText}/>
              <Skeleton variant="text" animation="wave" style={styles.skeletonText}/>
              <Skeleton variant="rounded" animation="wave" style={styles.skeletonBody} />
            </Paper>
          </Grid>
        ) : (
          // request more button
          <Grid
            item
            xs={12} sm={6} md={4} lg={3}
            style={styles.gridItem}
            className="homeGridHover"
            onClick={onRequestMore}
          >
            <Paper style={styles.requestPaper}>
              <Typography style={styles.requestTypo}>
                Request more games
              </Typography>
              <IconButton style={styles.requestButton}>
                <DoubleArrowIcon/>
              </IconButton>
            </Paper>
          </Grid>
        )}
      </Grid>

      <NewGameDialog
        open={isNewGameDialog}
        onClose={onCloseNewGameDialog}
        onResultCallback={onNewGame}
      />
    </Box>
  )
}

const styles: CustomStyle = {
  box: {
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  paper: {
    padding: '2rem',
  },
  grid: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
  requestPaper: {
    padding: '1.5rem',
    zIndex: 50,
    width: '95%',
    maxWidth: 700,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  skeletonText: {
    width: '100%',
    fontSize: '1.5vmax',
    padding: '1rem',
  },
  skeletonBody: {
    width: '100%',
    height: '100%',
    marginTop: '1rem',
  },
  requestTypo: {
    textAlign: 'center',
  },
  requestButton: {
    width: 'min-content'
  },
};

export type HomeProps = {
  setContextState: React.Dispatch<React.SetStateAction<ContextType>>,
}
