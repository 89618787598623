import "./GameGridImage.css";
import Miss from "../Images/GridImages/Miss.svg";
import Hit from "../Images/GridImages/Hit.svg";
import { Box } from "@mui/material";
import { CustomStyle } from "../Globals/Global.type";
import { imgGetter } from "../Globals/Global";
import { WarshipGameGrid } from "../Types/WarshipGame.type";
import { useMemo } from "react";

// generic grid image component
export default function GameGridImage(props: GameGridImageProps){
  // animation CSS value for the newest hits
  const animation = useMemo(() => {
    if(
      props.elem.animationCount === undefined
      || props.elem.animationIndex === undefined
    ) return undefined;

    return `newHit 2s infinite ease-out ${(props.elem.animationIndex - 1) / props.elem.animationCount * 1.5}s`;
  }, [props.elem.animationIndex, props.elem.animationCount]);

  // non ship images
  if(props.elem.shipSize === undefined){
    if(props.elem.isHit && props.elem.isShip) return <img alt="" src={Hit} style={{ ...styles.img, animation: animation }}/>
    else if(props.elem.isHit) return <img alt="" src={Miss} style={{ ...styles.img, animation: animation }}/>
    else return <Box onClick={() => props.onEmptyBoxClick?.(props.x, props.y)} style={styles.img} className={props.onEmptyBoxClick && 'gameGridImageHover'}></Box>;
  }

  // ship images
  return(
    <Box display={'flex'} style={styles.box}>
      <img alt="" style={props.elem.isHorizontal ? styles.nonRotation : styles.rotation} src={imgGetter(props.elem)}/>
      {props.elem.isHit && 
        <Box style={styles.innerBox}>
          <img alt="" src={Hit} style={{ animation: animation }}/>
        </Box>
      }
    </Box>
  )
}

const styles: CustomStyle = {
  img: {
    position: 'absolute',
    padding: '7.5%',
    inset: 0,
    width: '100%',
    height: '100%',
  },
  box: {
    position: 'absolute',
    padding: 0,
    inset: 0,
    width: '100%',
    height: '100%',
  },
  innerBox: {
    position: 'absolute',
    inset: 0,
    padding: '7.5%',
  },
  rotation: {
    transform: 'rotate(90deg)',
  },
  nonRotation: {
    transform: 'rotate(0deg)',
  },
}

type GameGridImageProps = {
  elem: WarshipGameGrid[number][number],
  x: number,
  y: number,
  onEmptyBoxClick?: (x: number, y: number) => Promise<void>,
}