import { Box } from "@mui/material";
import { CustomStyle } from "../../Globals/Global.type";
import { useDraggable } from "../../Globals/useDraggable";
import { dimension, imgGetter, isViewPortHorizontal } from "../../Globals/Global";
import { CSSProperties, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import Ship from "../../Types/Ship.type";

// rem to pixel ratio
const remSize = 16;

// component for a single draggable item when creating a custom game field
export default function FieldDraggable(props: FieldDraggableProps){
  //states
  const setShipsData = useMemo(() => props.setShipsData, [props.setShipsData]);
  const [isHorizontal, setHorizontal] = useState(true);
  const {draggableRef, dx, dy} = useDraggable({
    gridSize: dimension * remSize,
    dx: Math.floor(props.index % 2) * 5 + (isViewPortHorizontal ? 11 : 0),
    dy: Math.floor(props.index / 2) * 2,
    length: props.length,
    isHorizontal: isHorizontal,
  });

  // state setters
  const onContextMenu: MouseEventHandler<HTMLDivElement> = useCallback((e) => {e.preventDefault(); setHorizontal(val => !val); }, []);

  // update the parent whenever a position changes
  useEffect(() => {
    setShipsData(_val => {
      let val = [..._val];
      const index = val.findIndex(v => v.id === props.index);
      const newShipData: (typeof _val)[number] = {
        id: props.index,
        isHorizontal: isHorizontal,
        length: props.length,
        x: dx / (dimension * remSize),
        y: dy / (dimension * remSize),
      }

      if(index !== -1) val[index] = newShipData
      else val.push(newShipData);

      return val;
    });
  }, [dx, dy, isHorizontal, setShipsData, props.length, props.index]);

  // render a full ship
  return(
    <Box
      ref={draggableRef}
      style={{
        ...styles.draggable,
        ...getDimensions(props.length, isHorizontal),
        transform: `translate3d(${dx}px, ${dy}px, 0)`,
        flexDirection: isHorizontal ? 'row' : 'column',
      }}
      onContextMenu={onContextMenu}
    >
      {getShip(props.length, isHorizontal)}
    </Box>
  )
};

// ship builder components
const getShip = (length: number, isHorizontal: boolean) => {
  return(
    <>
      {[...Array(length)].map((elem, i) =>
        <img
          key={i}
          alt=""
          draggable={false}
          src={imgGetter({shipSize: length, shipElementIndex: i})}
          style={{
            ...styles.img,
            transform: `rotate(${isHorizontal ? 0 : 90}deg)`
          }}
        />
      )}
    </>
  )
}

// calculate the sizes of the component
const getDimensions = (length: number, isHorizontal: boolean): CSSProperties => ({
  width: isHorizontal ? `${dimension * length}rem` : `${dimension}rem`,
  height: isHorizontal ? `${dimension}rem` : `${dimension * length}rem`,
});

const styles: CustomStyle = {
  img: {
    width: `${dimension}rem`,
    height: `${dimension}rem`,
    userSelect: 'none',
    touchAction: 'none',
  },
  draggable: {
    position: 'absolute',
    left: 0,
    top: 0,

    /* Center the content */
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    /* Indicate that the element is draggable */
    cursor: 'move',
    userSelect: 'none',
    touchAction: 'none',
  },
}

type FieldDraggableProps = {
  length: number,
  index: number,
  setShipsData: React.Dispatch<React.SetStateAction<Omit<Ship, "isDestroyed" | "WarshipGameId" | "WarshipUserId">[]>>,
};
