import { Theme, createTheme } from "@mui/material";
import TwoLongFront from "../Images/GridImages/TwoLongFront.svg";
import TwoLongBack from "../Images/GridImages/TwoLongBack.svg";
import ThreeLongBack from "../Images/GridImages/ThreeLongBack.svg";
import ThreeLongMiddle from "../Images/GridImages/ThreeLongMiddle.svg";
import ThreeLongFront from "../Images/GridImages/ThreeLongFront.svg";
import FourLongFront from "../Images/GridImages/FourLongFront.svg";
import FourLongMiddleFront from "../Images/GridImages/FourLongMiddleFront.svg";
import FourLongMiddleBack from "../Images/GridImages/FourLongMiddleBack.svg";
import FourLongBack from "../Images/GridImages/FourLongBack.svg";
import FiveLongBack from "../Images/GridImages/FiveLongBack.svg";
import FiveLongMiddleBack from "../Images/GridImages/FiveLongMiddleBack.svg";
import FiveLongMiddle from "../Images/GridImages/FiveLongMiddle.svg";
import FiveLongMiddleFront from "../Images/GridImages/FiveLongMiddleFront.svg";
import FiveLongFront from "../Images/GridImages/FiveLongFront.svg";
import { WarshipGameGrid } from "../Types/WarshipGame.type";

export const themeKey = "warship-theme";
export const tokenName = "warship-token";
export const APIUrl = "https://rolandono.ath.cx/warshipapi/";

// check the orientation on load
export const isViewPortHorizontal = window.innerWidth > window.innerHeight;

// grid sizes
export const gridSizes = [10, 10];

// all the ships, 0 is a padding value
export const ships = [2, 2, 2, 0, 3, 3, 4, 4, 5];

// dimension by rem for the field creation
export const dimension = isViewPortHorizontal ? 2.25 : 1.6;

// switch to select from the ship icons
export const imgGetter = (elem: WarshipGameGrid[number][number]) => {
  if(elem.shipSize === undefined || elem.shipElementIndex === undefined) return undefined;

  switch(elem.shipSize.toString() + elem.shipElementIndex.toString()){
    case '20': return TwoLongFront;
    case '21': return TwoLongBack;
    case '30': return ThreeLongBack;
    case '31': return ThreeLongMiddle;
    case '32': return ThreeLongFront;
    case '40': return FourLongBack;
    case '41': return FourLongMiddleBack;
    case '42': return FourLongMiddleFront;
    case '43': return FourLongFront;
    case '50': return FiveLongFront;
    case '51': return FiveLongMiddleFront;
    case '52': return FiveLongMiddle;
    case '53': return FiveLongMiddleBack;
    case '54': return FiveLongBack;
    default: return undefined
  }
}

// themes
export const isDark: boolean = localStorage.getItem(themeKey)
  ? localStorage.getItem(themeKey) === "dark"
  : true;

export const darkTheme: Theme = createTheme(
  { palette: { mode: 'dark' } },
);

export const lightTheme: Theme = createTheme(
  { palette: { mode: 'light' } },
);
