import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import LoginStyles from "./Login.style";
import AuthenticationService from "../../Services/AuthenticationServices/AuthenticationService";
import { useNavigate } from "react-router-dom";
import customRouter from "../../Controllers/CustomRouter";
import authTokenValidatorService from "../../Services/AuthenticationServices/AuthTokenValidatorService";
import { tokenName } from "../../Globals/Global";
import { ContextType } from "../../Globals/Context";

export default function Login(props: LoginProps){
  // states
  const [isRegistering, setRegistering] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // events
  const onUsernameChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setUsername(e.target.value); }, []);
  const onPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setPassword(e.target.value); }, []);
  const onOpenRegistration = useCallback(() => setRegistering(true), []);
  const onCloseRegistration = useCallback(() => setRegistering(false), []);

  // login event
  const onLogin = useCallback(async () => {
    const response = await AuthenticationService.login({
      username: username,
      pass: password,
    });

    if(response.status === 200){
      localStorage.setItem(tokenName, `Bearer ${response.data.token.toString()}`);
      props.setContextState(val => {
        return {
          ...val,
          user: authTokenValidatorService(),
        }
      });
      navigate(customRouter.routes.home);
    }
  }, [username, password, navigate, props]);

  // registration event
  const onRegistration = useCallback(async () => {
    const response = await AuthenticationService.registration({
      username: username,
      pass: password,
    });

    if(response.status === 200){
      localStorage.setItem(tokenName, `Bearer ${response.data.token.toString()}`);
      props.setContextState(val => {
        return {
          ...val,
          user: authTokenValidatorService(),
        }
      });
      navigate(customRouter.routes.home);
    }
  }, [username, password, navigate, props]);

  // not allowing login page when a valid token is available
  useEffect(() => {
    if(authTokenValidatorService()) navigate(customRouter.routes.home);
  }, [navigate]);

  return(
    <Box style={LoginStyles.box}>
      {/* login form */}
      <Paper style={LoginStyles.paper}>
        <Box style={LoginStyles.paperBox}>
          <Typography variant="h3" align="center" margin={'1rem'}>Warship game</Typography>
          <TextField
            variant="standard"
            label="Username"
            placeholder="Type your username"
            value={username}
            onChange={onUsernameChange}
            style={LoginStyles.input}
          />
          <TextField
            variant="standard"
            label="Password"
            placeholder="********"
            value={password}
            onChange={onPasswordChange}
            style={LoginStyles.input}
            type="password"
          />
          <Button variant="contained" style={LoginStyles.loginButton} onClick={onLogin}>
            Login
          </Button>
          <Button variant="text" style={LoginStyles.registerButton} onClick={onOpenRegistration}>
            Register
          </Button>
        </Box>
      </Paper>

      {/* registration dialog */}
      <Dialog
        open={isRegistering}
        onClose={onCloseRegistration}
      >
        <DialogTitle textAlign={'center'}>Register to the game</DialogTitle>
        <DialogContent dividers>
          <Box style={LoginStyles.registerBox}>
            <TextField
              variant="standard"
              label="Username"
              placeholder="Type your username"
              value={username}
              onChange={onUsernameChange}
              style={LoginStyles.input}
            />
            <TextField
              variant="standard"
              label="Password"
              placeholder="********"
              value={password}
              onChange={onPasswordChange}
              style={LoginStyles.input}
              type="password"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={onRegistration}>Register!</Button>
          <Button color="error" onClick={onCloseRegistration}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export type LoginProps = {
  setContextState: React.Dispatch<React.SetStateAction<ContextType>>,
};
