import { Box, Paper } from "@mui/material";
import { CustomStyle } from "../../Globals/Global.type";
import FieldDraggable from "./FieldDraggable";
import { dimension, isViewPortHorizontal, ships } from "../../Globals/Global";
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import Ship from "../../Types/Ship.type";

// Box to create a custom field
const FieldCreator = forwardRef(function FieldCreator(props: FieldCreatorProps, ref: ForwardedRef<FieldCreatorRef>){
  // parent holding the ship data
  const [shipsData, setShipsData] = useState<Omit<Ship, "isDestroyed" | "WarshipGameId" | "WarshipUserId">[]>([]);

  // make the ship data available for the parent
  useImperativeHandle(ref, () => {
    return {
      shipsData: shipsData,
    };
  }, [shipsData]);

  return(
    <Paper style={styles.creatingField}>
      <Box style={styles.dragContainer}>
        {/* Some space used as padding, when the ship length is 0 */}
        {ships.map((s, i) => 
          s !== 0 && <FieldDraggable key={i} length={s} index={i} setShipsData={setShipsData}/>
        )}
      </Box>
    </Paper>
  )
});

const styles: CustomStyle = {
  creatingField: {
    padding: '1rem',
    overflow: 'auto',
    margin: '1rem auto',
    marginBottom: 0,
    width: `${21 * dimension}rem`,
    maxWidth: '100%',
  },
  dragContainer: {
    position: 'relative',
    backgroundImage: `
      linear-gradient(to right, rgb(203 213 225) 1px, transparent 1px),
      linear-gradient(to bottom, rgb(203 213 225) 1px, transparent 1px)
    `,
    backgroundSize: `${dimension}rem ${dimension}rem`,
    width: `calc(${dimension * 10}rem + 1px)`,
    height: `calc(${dimension * 10}rem + 1px)`,
    margin: isViewPortHorizontal ? 0 : 'auto',
  },
};

export type FieldCreatorRef = {
  shipsData: Omit<Ship, "isDestroyed" | "WarshipGameId" | "WarshipUserId">[],
};

type FieldCreatorProps = {};

export default FieldCreator;
