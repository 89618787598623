import customRouter from "../../Controllers/CustomRouter";
import { APIUrl, tokenName } from "../../Globals/Global";
import { ResponseModel } from "../../Globals/Global.type";
import AuthenticationLoginData from "../../Models/Authentication/AuthenticationLoginData.type";
import AuthenticationLoginResponse from "../../Models/Authentication/AuthenticationLoginResponse.type";
import fetchData from "../fetchData";
import AuthenticationRegistrationData from "../../Models/Authentication/AuthenticationRegistrationData.type";
import AuthenticationRegistrationResponse from "../../Models/Authentication/AuthenticationRegistrationResponse.type";
import errorHandling from "../errorHandling";

class LocalService {
  async login(data: AuthenticationLoginData): Promise<ResponseModel<AuthenticationLoginResponse>> {
    const response: ResponseModel<AuthenticationLoginResponse> = await fetchData(
      APIUrl + customRouter.apiRoutes.authentication.login,
      'POST',
      {},
      data
    );

    // error handling
    if(response.status !== 200){
      errorHandling(response);
    }
    
    // function logic
    if(response.status === 200){
      localStorage.setItem(tokenName, `Bearer ${response.data.token}`);
    }

    return response;
  }

  async registration(data: AuthenticationRegistrationData): Promise<ResponseModel<AuthenticationRegistrationResponse>> {
    const response: ResponseModel<AuthenticationRegistrationResponse> = await fetchData(
      APIUrl + customRouter.apiRoutes.authentication.registration,
      'POST',
      {},
      data
    );

    // error handling
    if(response.status !== 200){
      errorHandling(response);
    }
    
    // function logic
    if(response.status === 200){
      localStorage.setItem(tokenName, `Bearer ${response.data.token}`);
    }

    return response;
  }
}

const AuthenticationService = new LocalService();

export default AuthenticationService;
