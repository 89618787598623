import { useCallback, useEffect, useMemo, useState } from "react";

// hook for the draggable elements when creating a new game
export const useDraggable = (props: useDraggableProps): useDraggableResult => {
  // states
  const [node, setNode] = useState<HTMLElement | null>(null);
  // current positions of the element
  const [{ dx, dy }, setOffset] = useState({
    dx: props.dx * props.gridSize,
    dy: props.dy * props.gridSize,
  });
  // max values to prevent dragging outside of the component
  const maxValues = useMemo(() => ({
    x: props.isHorizontal
      ? props.gridSize * (19 - props.length + 1)
      : props.gridSize * 19,
    y: props.isHorizontal
      ? props.gridSize * 9
      : props.gridSize * (9 - props.length + 1),
  }), [props.isHorizontal, props.gridSize, props.length]);

  const ref = useCallback((nodeEle: HTMLElement) => {
    setNode(nodeEle);
  }, []);

  // drag start for mouse
  const handleMouseDown = useCallback((e: MouseEvent) => {
    const startPos = {
      x: e.clientX - dx,
      y: e.clientY - dy,
    };

    const handleMouseMove = (e: MouseEvent) => {
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      const snappedX = Math.min(Math.max(Math.round(dx / props.gridSize) * props.gridSize, 0), maxValues.x);
      const snappedY = Math.min(Math.max(Math.round(dy / props.gridSize) * props.gridSize, 0), maxValues.y);
      
      setOffset({ dx: snappedX, dy: snappedY });
      updateCursor();
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      resetCursor();
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [dx, dy, props.gridSize, maxValues.x, maxValues.y]);

  // drag start for touch
  const handleTouchStart = useCallback((e: TouchEvent) => {
    const touch = e.touches[0];

    const startPos = {
      x: touch.clientX - dx,
      y: touch.clientY - dy,
    };

    const handleTouchMove = (e: TouchEvent) => {
      const touch = e.touches[0];
      const dx = touch.clientX - startPos.x;
      const dy = touch.clientY - startPos.y;
      const snappedX = Math.min(Math.max(Math.round(dx / props.gridSize) * props.gridSize, 0), maxValues.x);
      const snappedY = Math.min(Math.max(Math.round(dy / props.gridSize) * props.gridSize, 0), maxValues.y);

      setOffset({ dx: snappedX, dy: snappedY });
      updateCursor();
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      resetCursor();
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  }, [dx, dy, props.gridSize, maxValues.x, maxValues.y]);

  useEffect(() => {
    const snappedX = Math.min(Math.max(Math.round(dx / props.gridSize) * props.gridSize, 0), maxValues.x);
    const snappedY = Math.min(Math.max(Math.round(dy / props.gridSize) * props.gridSize, 0), maxValues.y);
    
    setOffset({ dx: snappedX, dy: snappedY });
  }, [dx, dy, maxValues.x, maxValues.y, props.gridSize, props.isHorizontal]);

  useEffect(() => {
    if (!node) return;

    node.addEventListener("mousedown", handleMouseDown);
    node.addEventListener("touchstart", handleTouchStart);
    return () => {
      node.removeEventListener("mousedown", handleMouseDown);
      node.removeEventListener("touchstart", handleTouchStart);
    };
  }, [node, dx, dy, handleMouseDown, handleTouchStart]);

  // return with the ref and the positions
  return {
    draggableRef: ref,
    dx: dx,
    dy: dy,
  };
};

// cursor events
const updateCursor = () => {
  document.body.style.cursor = 'crosshair';
  document.body.style.userSelect = 'none';
};

const resetCursor = () => {
  document.body.style.removeProperty('cursor');
  document.body.style.removeProperty('user-select');
};

type useDraggableProps = {
  gridSize: number,
  dx: number,
  dy: number,
  length: number,
  isHorizontal: boolean,
}

type useDraggableResult = {
  draggableRef: (nodeEle: HTMLElement) => void,
  dx: number,
  dy: number,
}