import customRouter from "../../Controllers/CustomRouter";
import { APIUrl, gridSizes, tokenName } from "../../Globals/Global";
import { ResponseModel } from "../../Globals/Global.type";
import fetchData from "../fetchData";
import GetGamesData from "../../Models/Game/GetGamesData.type";
import GetGamesResponse from "../../Models/Game/GetGamesResponse.type";
import CreateGameData from "../../Models/Game/CreateGameData.type";
import CreateGameResponse from "../../Models/Game/CreateGameResponse.type";
import WarshipGame from "../../Types/WarshipGame.type";
import WarshipUser from "../../Types/WarshipUser.type";
import Pagination from "../../Types/Pagination.type";
import GetGameData from "../../Models/Game/GetGameData.type";
import GetGameResponse from "../../Models/Game/GetGameResponse.type";
import errorHandling from "../errorHandling";
import Ship from "../../Types/Ship.type";
import { toast } from "react-toastify";

class LocalService {
  /************
   * REQUESTS *
   ************/
  async getGame(data: GetGameData): Promise<ResponseModel<GetGameResponse>> {
    const response: ResponseModel<GetGameResponse> = await fetchData(
      APIUrl + customRouter.apiRoutes.game.getGame,
      'POST',
      { Authorization: localStorage.getItem(tokenName) },
      data
    );

    // error handling
    if(response.status !== 200){
      errorHandling(response);
    }

    return response;
  }

  async getGames(data: (GetGamesData & Pagination)): Promise<ResponseModel<GetGamesResponse>> {
    const response: ResponseModel<GetGamesResponse> = await fetchData(
      APIUrl + customRouter.apiRoutes.game.getGames,
      'POST',
      { Authorization: localStorage.getItem(tokenName) },
      data
    );

    // error handling
    if(response.status !== 200){
      errorHandling(response);
    }

    return response;
  }

  async createGame(data: CreateGameData): Promise<ResponseModel<CreateGameResponse>> {
    const response: ResponseModel<CreateGameResponse> = await fetchData(
      APIUrl + customRouter.apiRoutes.game.createGame,
      'POST',
      { Authorization: localStorage.getItem(tokenName) },
      data
    );

    // error handling
    if(response.status !== 200){
      errorHandling(response);
    }

    return response;
  }

  
  /*********
   * UTILS *
   *********/
  gameToGameOverText(game: WarshipGame, user: WarshipUser | undefined, onGoingGameText: string = ''): string {
    if(!game.isFinished || !game.Ships) return onGoingGameText;

    if(
      // all ships are destroyed
      game.Ships.filter(s => !s.isDestroyed).length === 0
      // and the shiup number by users is the same
      && game.Ships.filter(s => s.WarshipUserId === user?.id).length === game.Ships.filter(s => s.WarshipUserId !== user?.id).length
    ) return `It's a draw!`;
    // there are unsunken ships (its the user's)
    else if(game.Ships.filter(s => !s.isDestroyed).length === 0) return `Your opponent has won!`
    else return `You have won!`;
  }

  validateShipsData = (shipsData: Omit<Ship, "isDestroyed" | "WarshipGameId" | "WarshipUserId">[] | undefined): [isValid: boolean, validShipsData: Omit<Ship, "isDestroyed" | "WarshipGameId" | "WarshipUserId">[]] => {
    if(!shipsData) return [false, []];
    
    const grid: number[][] = Array.from(Array(gridSizes[1]), () => new Array(gridSizes[0]));
    grid.forEach(row => row.fill(0));

    for(let i = 0; i < shipsData.length; i++){
      if(!this.isShipFitable(grid, shipsData[i].x, shipsData[i].y, shipsData[i].length, shipsData[i].isHorizontal)){
        toast.error('The ships are not placed correctly!');
        return [false, []];
      }

      // place the ship on the grid for easier validation for the later ships
      for(let j = 0; j < shipsData[i].length; j++){
        if(shipsData[i].isHorizontal) grid[shipsData[i].y][shipsData[i].x + j] += 1;
        else grid[shipsData[i].y + j][shipsData[i].x] += 1;
      }
    };

    return [true, shipsData];
  }

  // check the current grid, whether the defined ship can fit or not
  // no ship can be placed next to each other, not even diagonally
  // NOTE: same function as in the API
  isShipFitable = (
    grid: number[][], x: number, y: number,
    shipSize: number, isHorizontal: boolean
  ): boolean => {
    // check for overflowing ships
    if(
      (isHorizontal && x + shipSize > grid[0].length)
      || (!isHorizontal && y + shipSize > grid.length)
    ) return false;

    for(let i = -1; i <= 1; i++){               // i: margin index
      for(let j = -1; j < shipSize + 1; j++){   // j: ship length + margin index
        let verticalIndex: number, horizontalIndex: number;
        
        if(isHorizontal){
          verticalIndex = y + i;
          horizontalIndex = x + j;
        }
        else{
          verticalIndex = y + j;
          horizontalIndex = x + i;
        }
  
        // continue on out of grid values
        if(
          verticalIndex < 0
          || verticalIndex > grid.length - 1
          || horizontalIndex < 0
          || horizontalIndex > grid[0].length - 1
        ) continue;
  
        // when the given coords has already got a ship, the current one is unfittable
        if(grid[verticalIndex][horizontalIndex] === 1) return false;
      }
    }
  
    return true;
  }
}

const GameService = new LocalService();

export default GameService;
