import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { WarshipGameWithGrid, warshipGameDifficultyToString, warshipGameToOpponentName } from "../Types/WarshipGame.type";
import GameGridImage from "./GameGridImage";
import { CustomStyle } from "../Globals/Global.type";
import { useCallback, useContext, useMemo } from "react";
import Context from "../Globals/Context";
import GameService from "../Services/GameServices/GameService";

export default function GameGridHomeIcon(props: GameGridHomeIconProps){
  const context = useContext(Context);
  const gameOverText = useMemo(() => {
    return GameService.gameToGameOverText(props.game, context.user, 'Game is still ongoing');
  }, [props.game, context.user]);

  const onGridClick = useCallback((game: WarshipGameWithGrid) => {
    props.onGoToGame(game.id)
  }, [props]);

  return(
    <Grid
      item
      xs={12} sm={6} md={4} lg={3}
      style={styles.gridItem}
      className="homeGridHover"
      onClick={() => onGridClick(props.game)}
    >
      <Paper style={styles.paper}>
        {/* Infos about the game */}
        <Box>
          <Typography style={styles.gameOverText}>{gameOverText}</Typography>
          <Typography style={styles.modeText}>
            {props.game.isUsingHitRule ? 'Hit rule mode' : 'Normal mode'}{warshipGameDifficultyToString(props.game)}
          </Typography>
          <Typography style={styles.infoText}>
            Against: {warshipGameToOpponentName(props.game, context.user)}
          </Typography>
        </Box>

        {/* Game itself */}
        {props.game.grid.map((row, i) => {
          return(
            <Stack key={i} style={styles.paperStack}>
              {row.map((elem, j) =>
                <Box style={styles.gridItemBox} key={j}>
                  <GameGridImage
                    x={j}
                    y={i}
                    key={`${i}${j}`}
                    elem={elem}
                  /> 
                </Box>
              )}
            </Stack>
          )
        })}
      </Paper>
    </Grid>
  )
};

const styles: CustomStyle = {
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '1rem',
    height: '100%',
  },
  gridItemBox: {
    width: '10%',
    paddingTop: '10%',
    position: 'relative',
    border: '1px solid white',
    backgroundColor: 'rgb(40, 40, 40)',
  },
  paper: {
    padding: '1.5rem',
    zIndex: 50,
    width: '95%',
    maxWidth: 700,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  gameOverText: {
    textAlign: 'center',
    fontSize: '1.5vmax',
  },
  modeText: {
    textAlign: 'center',
    fontSize: '0.75vmax',
    marginBottom: 0,
  },
  infoText: {
    textAlign: 'center',
    fontSize: '0.75vmax',
    marginBottom: '1rem',
  },
  paperStack: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
};

type GameGridHomeIconProps = {
  game: WarshipGameWithGrid,
  onGoToGame: (id: number) => void,
};
