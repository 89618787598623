// -----------------------------------------------------------------Imports---
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { ResponseModel } from '../Globals/Global.type';

// ----------------------------------------------------------------Privates---
const SendWithAxios = async <T,>(
  url: string,
  method: AxiosRequestConfig['method'],
  headers?: AxiosRequestConfig['headers'],
  data?: AxiosRequestConfig['data'],
): Promise<T> => {
  const config: AxiosRequestConfig = {
    url: url,
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    data: data,
  }

  return await axios(config);
}

const fetchData = async <T>(
  url: string,
  method: AxiosRequestConfig['method'],
  header?: AxiosRequestConfig['headers'],
  data?: AxiosRequestConfig['data'],
): Promise<ResponseModel<T>> => {
  try {
    const response: AxiosResponse<ResponseModel<T>> = await SendWithAxios(url, method, header, data);

    return response.data;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError<ResponseModel<T>> = error;

      if (axiosError.response) {
        return axiosError.response?.status === 403
          ? {
            status: axiosError.response?.status,
            errorMessage: 'Unsuccessful authorization',
          }
          : axiosError.response?.data || {
            status: 400,
            errorMessage:  'Unknown error encountered',
          };
      }

      return {
        status: 400,
        errorMessage: `${axiosError.message} [${axiosError.name}, ${axiosError.code}]`,
      }
    }

    return {
      status: 500,
      errorMessage: 'Network error',
    };
  }
}

// -----------------------------------------------------------------Exports---
export default fetchData;
