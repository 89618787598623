import customRouter from "../../Controllers/CustomRouter";
import { APIUrl, tokenName } from "../../Globals/Global";
import { ResponseModel } from "../../Globals/Global.type";
import fetchData from "../fetchData";
import CreateHit from "../../Models/Hit/CreateHitData.type";
import CreateHitResponse from "../../Models/Hit/CreateHitResponse.type";
import errorHandling from "../errorHandling";

class LocalService {
  async createHit(data: CreateHit): Promise<ResponseModel<CreateHitResponse>> {
    const response: ResponseModel<CreateHitResponse> = await fetchData(
      APIUrl + customRouter.apiRoutes.hit.createHit,
      'POST',
      { Authorization: localStorage.getItem(tokenName) },
      data
    );

    // error handling
    if(response.status !== 200){
      errorHandling(response);
    }

    return response;
  }
}

const HitService = new LocalService();

export default HitService;
